<template>
	<div class="collaboration-area">
		<div class="collaboration-area-form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
				<el-form-item :label="$t('artname')" prop="name">
					<el-input v-model="ruleForm.name" :placeholder="$t('songartname')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('isrealname')" prop="realname">
					<el-input v-model="ruleForm.realname" clearable :placeholder="$t('isrealnametitle')" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('country')" prop="country">
					<el-select @change="countryChange" v-model="ruleForm.country" :placeholder="$t('selectcountry')" popper-class="reset-popper-bg-color"
						style="width: 100%;">
						<el-option v-for="item in countrys" :key="item.country" :label="item.country" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="ruleForm.country == 2" :label="$t('kakaotalkID')" prop="kakaotalkID">
					<el-input v-model="ruleForm.kakaotalkID" clearable :placeholder="$t('placeholder') + $t('kakaotalkID')" ></el-input>
				</el-form-item>
				<el-form-item v-if="ruleForm.country == 2" :label="$t('insAccount')" prop="insAccount">
					<el-input v-model="ruleForm.insAccount" clearable :placeholder="$t('placeholder') + $t('insAccount')" ></el-input>
				</el-form-item>
				<!-- <el-form-item :label="$t('mobile')" prop="phone">
					<el-input v-model="ruleForm.phone" :placeholder="$t('mobiletitle')"></el-input>
				</el-form-item> -->
				<el-form-item :label="$t('headImg')" prop="headImg">
					<div class="upimg coverup">
						<el-upload :action="uploadImageUrl" list-type="picture-card" :on-success="callbackHeadImg"
							:limit="1" :before-upload="testformatHeadImg" name="image" ref="HeadImg">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemoveHeadImg(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<span slot="tip" class="el-upload__tip"
								style="line-height: 80px;">{{$t("supportpng")}}</span>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item :label="$t('desc')" prop="desc">
					<el-input type="textarea" v-model="ruleForm.desc" :placeholder="$t('deschome')" maxlength="500"
						show-word-limit></el-input>
				</el-form-item>
				<!-- <el-form-item label="认证类型" prop="type">
					<el-select v-model="ruleForm.type" popper-class="reset-popper-bg-color" style="width: 100%;">
					  <el-option
					    label="个人"
					    value="1"
					  ></el-option>
					  <el-option
					    label="团队"
					    value="2"
					  ></el-option>
					</el-select>
				</el-form-item> -->
			</el-form>
		</div>
		<!-- <div style="width: 100%;"> -->
		<!-- <el-divider></el-divider>
			<div class="collaboration-area-form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="证件类型" prop="certificates">
						<el-select v-model="ruleForm.certificates" popper-class="reset-popper-bg-color" style="width: 100%;">
						  <el-option
						    :label="twotepCertType1"
						    value="1"
						    >{{ $t("UploadOpus.twotepCertType1") }}</el-option
						  >
						 <el-option
						    :label="twotepCertType2"
						    value="2"
						    >{{ $t("UploadOpus.twotepCertType2") }}</el-option
						  >
						  <el-option
						    v-if="gangaotai"
						    :label="twotepCertType3"
						    value="3"
						    >{{ $t("UploadOpus.twotepCertType3") }}</el-option
						  >
						  <el-option
						    v-if="gangaotai"
						    :label="twotepCertType4"
						    value="4"
						    >{{ $t("UploadOpus.twotepCertType4") }}</el-option
						  >
						  <el-option
						    v-if="gangaotai"
						    :label="twotepCertType5"
						    value="5"
						    >{{ $t("UploadOpus.twotepCertType5") }}</el-option
						  >
						</el-select>
					</el-form-item>
					<el-form-item label="证件号码" prop="idcard">
						<el-input v-model="ruleForm.idcard" @input="testid" clearable placeholder="请输入证件号码,审核通过后不可更改"></el-input>
					</el-form-item>
					<el-form-item label="上传证件">
						<el-upload :action="uploadImageUrl" list-type="picture-card" :on-success="uphuzhao"
							:limit="1" :before-upload="testformatHuzhao" name="image" ref="huzhao">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{ file }">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="removehuzhao(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<span slot="tip" class="el-upload__tip"
								style="line-height: 80px;">支持jpg/png格式，大小不超过10M</span>
						</el-upload>
					</el-form-item>
					<el-form-item label="真实姓名" prop="realname">
						<el-input v-model="ruleForm.realname" clearable placeholder="请输入真实姓名,审核通过后不可更改"></el-input>
					</el-form-item>
					<el-form-item label="国籍" prop="country">
						<el-select
						  v-model="ruleForm.country"
						  placeholder="请选择国籍"
						  popper-class="reset-popper-bg-color" style="width: 100%;"
						>
						  <el-option
						    v-for="item in countrys"
						    :key="item.country"
						    :label="item.country"
						    :value="item.id"
						  >
						  </el-option>
						</el-select>
					</el-form-item>
					
				</el-form>
			</div> -->
		<el-divider></el-divider>
		<!-- </div> -->
		<div class="collaboration-area-form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
				<!-- <el-form-item :label="$t('songName')" prop="title">
					<el-input v-model="ruleForm.title" :placeholder="$t('placeholder')+ $t('Uploadsongtitle')"></el-input>
				</el-form-item> -->

				<!-- <el-form-item class="sex" :label="$t('workType')" prop="workType">
					<el-radio-group v-model="ruleForm.workType">
						<el-radio :label="0">{{$t('recommend.finishedMusic')}}</el-radio>
						<el-radio :label="1">{{$t('seademo')}}</el-radio>
					</el-radio-group>
				</el-form-item> -->
				<!-- <el-form-item label="AR" prop="name">
					<el-upload class="upload-demo" :before-upload="testformatAr" :action="uploadMusicFileUrl"
						:on-preview="handlePreview" :on-remove="removeAR"
						:on-success="callbackAR" ref="AR" multiple :limit="1" :on-exceed="handleExceed"
						:file-list="fileList">
						<el-button size="small" plain>
							<div class="btn-wrap">
								<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16"
									height="16"></Icon>
								<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
							</div>
						</el-button>
					</el-upload>
				</el-form-item> -->
				<!-- <el-form-item label="MR" prop="MRUrl">
					<el-upload class="upload-demo" :before-upload="testformatMr" :action="uploadMusicFileUrl"
						:on-preview="handlePreviewMR" :on-remove="removeMR"
						:on-success="callbackMR" ref="MR" multiple :limit="1" :on-exceed="handleExceedMR"
						:file-list="fileListMR">
						<el-button size="small" plain>
							<div class="btn-wrap">
								<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16"
									height="16"></Icon>
								<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
							</div>
						</el-button>
					</el-upload>
					</el-upload>
				</el-form-item> -->
				<!-- <el-form-item label="Multi" prop="MultiUrl">
					<el-upload class="upload-demo" :before-upload="testformatMulti" :action="uploadMusicFileUrl"
						:on-preview="handlePreviewMulti" :on-remove="removeMulti"
						:on-success="callbackMulti" ref="Multi" multiple :limit="1" :on-exceed="handleExceedMulti"
						:file-list="fileListMulti">
						<el-button size="small" plain>
							<div class="btn-wrap">
								<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16"
									height="16"></Icon>
								<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
							</div>
						</el-button>
					</el-upload>
					</el-upload>
				</el-form-item> -->
				<!-- <el-form-item label="BPM" prop="bpm">
					<el-input v-model="ruleForm.bpm" :placeholder="$t('songspeed')"></el-input>
				</el-form-item>
				<el-form-item label="KEY" prop="key">
					<el-cascader :options="keysarr" :props="{multiple:true}" @change="changeKeyCascader"
						v-model="keyValue" popper-class="reset-popper-bg-color" clearable :placeholder="$t('songtone')">
					</el-cascader>
				</el-form-item> -->
				<!-- <el-form-item :label="$t('cover')">
					<el-upload :action="uploadImageUrl" list-type="picture-card" :on-success="callbackcover" :limit="1"
						:before-upload="testformatCover" name="image" ref="cover">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span v-if="!disabled" class="el-upload-list__item-delete"
									@click="handleRemoveCover(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<span slot="tip" class="el-upload__tip"
							style="line-height: 80px;">{{ $t('uploadTip') }}</span>
					</el-upload>
				</el-form-item> -->

				<!-- <el-form-item :label="$t('singSinger')" prop="name">
					<el-cascader :options="singerList" :props="{multiple:true}"
						:placeholder="$t('pleaseSelectSuitSinger')" v-model="singerValue" @change="changeSingerCascader"
						popper-class="reset-popper-bg-color" clearable></el-cascader>
				</el-form-item> -->
				<el-form-item :label="$t('songStyle')" prop="name">
					<el-cascader :options="styleList" :placeholder="$t('pleaseSelectSongStyle')"
						:props="{multiple:true}" v-model="styleValue" @change="changeStyleCascader"
						popper-class="reset-popper-bg-color" clearable></el-cascader>
				</el-form-item>
				<el-form-item :label="$t('musicalInstrument')" prop="name">
					<el-cascader :options="musicalList" :placeholder="$t('pleaseSelectSongUseInstrument')"
						:props="{multiple:false}" v-model="musicalValue" @change="changeMusicalCascader"
						popper-class="reset-popper-bg-color" clearable></el-cascader>
				</el-form-item>
				<!-- <el-form-item :label="$t('emotion')" prop="name">
					<el-cascader :options="moodList" :placeholder="$t('pleaseSelectSongMood')" :props="{multiple:true}"
						v-model="moodValue" @change="changeMoodCascader" popper-class="reset-popper-bg-color" clearable>
					</el-cascader>
				</el-form-item> -->
				<el-form-item label="" prop="name">
					<el-checkbox v-model="xieyi">{{ $t("readAndAgree") }}
					</el-checkbox>
					<span @click="showAgreement(4)" style="color: #3875FB; cursor: pointer;">《{{
              $t('musicianOccupancyAgreement')
            }}》</span>
				</el-form-item>
				<div class="submit">
					<el-button @click="toregisterCreator" type="primary">{{ $t('submitreport') }}</el-button>
				</div>
			</el-form>
		</div>
		<!-- 协议弹窗 -->
		<AgreementPopup ref="popupAgreement"></AgreementPopup>
	</div>
</template>

<script>
	import _ from "lodash";
	import {
		tagList
	} from "@/api/Home";
	import {
		suitList
	} from "@/api/Common";
	import {
		addMusic,
		keyList,
		registerCreator,
		getCountryList
	} from "@/api/Upfile"
	import AgreementPopup from "@/components/popup/AgreementPopup.vue"
	export default {
		props: {},
		data() {
			return {
				disabled: false,

				ruleForm: {
					name: '', //艺名
					// headImgUrl: '',//头像
					desc: '', //个人描述
					// type: "1",//认证类型

					phone: '', //电话号
					// certificates: "1",//证件类型
					idcard: '', //证件号码
					// huzhaoUrl: '',//证件照正面
					// realname: '',//真实姓名
					country: "",//国家
					// countryId: "1",
					kakaotalkID: '',
					insAccount: '',

					title: '', //歌曲名称
					coverImg: '', //封面
					workType: 0, //作品类型

					// creator: '', //制作人 username
					// url: '', //AR路径
					// singer: '',
					// lyrics: '',
					// composition: '',
					// arrangements: '',
					urlAR: '', //AR
					urlMR: '',
					urlMulti: '', //Multi
					key: '',
					bpm: '',
				},
				// ruleForm1: {
				// 	name: '',//艺名
				// 	desc: '',//个人描述
				// 	phone: '';//电话号
				// },
				rules: {
					title: [{
						// required: true,
						message: this.$t('placeholder') + this.$t('songName'),
						trigger: 'blur'
					}, ],
					creator: [{
						required: true,
						message: '',
						trigger: 'blur'
					}],
					url: [{
						type: 'date',
						required: true,
						message: this.$t("uploadAr"),
						trigger: 'change'
					}],
				},
				countrys: [], //国家列表
				// fileList: [],
				// imageUrl: '', //图片路径
				xieyi: false, //协议
				uploadMusicFileUrl: process.env.VUE_APP_UPLOAD_MUSIC_URL, //上传AR
				uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL, //上传图片路径
				// uploadImageUrl: process.env.VUE_APP_FILE_PATH, //上传图片路径
				styleList: [],
				singerList: [],
				musicalList: [],
				moodList: [],
				singerValue: [],
				styleValue: [],
				musicalValue: "",
				moodValue: [],
				// checked: false,
				keysarr: [],
				keyValue: [],
				// uploadMusicFileUrl: process.env.VUE_APP_UPLOAD_MUSIC_URL, //上传AR
				// uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL, //上传图片路径
				fileList: [],
				fileListMR: [],
				fileListMulti: [],
				// imageUrl: '',//图片路径
				gangaotai: false, //港澳台存在


				twotepCertType1: this.$t(
					"UploadOpus.twotepCertType1"
				),
				twotepCertType2: this.$t(
					"UploadOpus.twotepCertType2"
				),
				twotepCertType3: this.$t(
					"UploadOpus.twotepCertType3"
				),
				twotepCertType4: this.$t(
					"UploadOpus.twotepCertType4"
				),
				twotepCertType5: this.$t(
					"UploadOpus.twotepCertType5"
				),
				activity: this.$route.query.activity || "",
				agreeType: 4,
				// options: [{
				// 		value: 1,
				// 		label: '男歌手',
				// 	},
				// 	{
				// 		value: 2,
				// 		label: '女歌手',
				// 	},
				// 	{
				// 		value: 3,
				// 		label: '男歌手1',
				// 	}]

			}
		},
		components: {
			AgreementPopup
		},
		computed: {
			styleValues() {
				return this.styleValue.map((item) => {
					if (_.isArray(item)) {
						return _.last(item);
					} else {
						return item;
					}
				});
			},
		},
		created() {},
		mounted() {
			let locale = localStorage.getItem("lang");
			if (locale == "cn") {
				this.gangaotai = true;
				this.country = "中国";
			} else if (locale == "ko") {
				this.gangaotai = false;
				this.country = "Korea";
			} else if (locale == "en") {
				this.gangaotai = false;
				this.country = "English";
			}
			this.getKeyList()
			this.initTagList()
			console.log(this.$store.state, 'this.$store.state')
			this.ruleForm.creator = this.$store.state.loginname;
			console.log(process.env.VUE_APP_FILE_PATH, 'process.env.VUE_APP_FILE_PATH')
			getCountryList().then((res) => {
				this.countrys = res.data.list;
				console.log(res.data.list);
			});
		},
		methods: {
			showAgreement(type) {
			  this.agreeType = type
			  this.$refs.popupAgreement.open()
			  console.log('协议',this.agreeType,'2222',type,'1111')
			  this.$refs.popupAgreement.getAgreement(this.agreeType)
			},
			countryChange(e){
				console.log(e,this.ruleForm.country,'countryChange');
			},
			// 音调列表
			getKeyList() {
				keyList().then(res => {
					console.log()
					let keys = Object.assign(res.data.list[1], res.data.list[2])

					for (let key in keys) {
						let obj = {}
						// obj.key = keys[key]
						// obj.toneId = key
						obj.label = keys[key]
						obj.value = key
						this.keysarr.push(obj)
					}

					// let singerOption = {}
					// for (const key in res.data.list.suit_arr) {
					// 	console.log(key)
					// 	singerOption.value = key
					// 	singerOption.label = res.data.list.suit_arr[key]
					// 	this.singerList.push(JSON.parse(JSON.stringify(singerOption)))
					// }

				})
				console.log(this.keysarr, 'getKeyList')
			},
			// 上传头像成功
			callbackHeadImg(res) {
				this.ruleForm.headImgUrl = this.uploadMusicFileUrl + '/' + res.data.url;
				console.log(this.ruleForm.url, 'this.ruleForm.url')
			},
			// 头像格式校验
			testformatHeadImg(file) {
				const cover_isJPG = file.type === "image/jpeg";
				const cover_isPNG = file.type === "image/png";
				const cover_isLt10M = file.size / 1024 / 1024 < 10;
				if (cover_isJPG || cover_isPNG) {
					if (!cover_isLt10M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("uploadheadimgformat"),
					});
					return false;
				}
			},
			// 移出头像
			removeHeadImg() {
				this.$refs.HeadImg.clearFiles();
			},
			// 校验身份证号
			testid() {
				// let reg1 = /\d{15}|\d{17}(\d|x)/i;
				// let reg2 = /\d{6}\-\d{7}/;
				// let reg3 = /^[a-zA-Z][A-Za-z0-9]+$/;
				// let a, b, c;
				// if (this.idcard === "") {
				//   this.idcardwarning = false;
				// } else {
				//   if (this.certificates == 2) {
				//     c = reg3.test(this.idcard);
				//     if (c) {
				//       this.idcardwarning = false;
				//     } else {
				//       this.idcardwarning = true;
				//     }
				//   } else {
				//     a = reg1.test(this.idcard);
				//     b = reg2.test(this.idcard);
				//     if (a || b) {
				//       this.idcardwarning = false;
				//     } else {
				//       this.idcardwarning = true;
				//     }
				//   }
				// }
			},
			// 上传证件成功
			uphuzhao(res) {
				this.ruleForm.huzhaoUrl = res.data.url;
			},
			// 证件格式校验
			testformatHuzhao(file) {
				console.log(file);
				const huzhao_isJPG = file.type === "image/jpeg";
				const huzhao_isPNG = file.type === "image/png";
				const huzhao_isLt10M = file.size / 1024 / 1024 < 10;
				if (huzhao_isJPG || huzhao_isPNG) {
					if (!huzhao_isLt10M) {
						console.log("文件太大了");
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {
						console.log("完美");
					}
				} else {
					console.log("文件格式不对");
					this.$message({
						type: "error",
						message: this.$t("authenticationrightcertimg"),
					});
					return false;
				}
			},
			// 移出证件
			removehuzhao() {
				this.$refs.huzhao.clearFiles();
			},

			// 上传AR成功
			callbackAR(res) {
				this.ruleForm.urlAR = this.uploadMusicFileUrl + '/' + res.data.url;
				console.log(this.ruleForm.urlAR, 'this.ruleForm.url')
			},
			// AR格式校验
			testformatAr(file) {
				const ar_isWAV = file.type === "audio/wav";
				const ar_isMP3 = file.type === "audio/mpeg";
				const ar_isLt100M = file.size / 1024 / 1024 < 100;
				// if( ar_isWAV || ar_isMP3){
				if (ar_isMP3) {
					if (!ar_isLt100M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightAr"),
					});
					return false;
				}
			},
			// 移出AR
			removeAR() {
				this.$refs.AR.clearFiles();
			},
			// AR预览
			handlePreview(file) {
				console.log(file);
			},
			// AR移出前
			beforeRemove(file, fileList) {
				return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
			},
			// AR 
			handleExceed(files, fileList) {
				this.$message.warning(
					`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileList.length} ${$t("filenum")}`);
			},
			// 上传MR成功
			callbackMR(res) {
				this.ruleForm.urlMR = this.uploadMusicFileUrl + '/' + res.data.url;
				console.log(this.ruleForm.urlMR, 'this.ruleForm.urlMR')
			},
			// MR校验格式
			testformatMr(file) {
				const ar_isWAV = file.type === "audio/wav";
				const ar_isMP3 = file.type === "audio/mpeg";
				const ar_isLt100M = file.size / 1024 / 1024 < 100;
				// if( ar_isWAV || ar_isMP3){
				if (ar_isMP3) {
					if (!ar_isLt100M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightmr"),
					});
					return false;
				}
			},
			// 移出MR
			removeMR() {
				this.$refs.MR.clearFiles();
			},
			// MR预览
			handlePreviewMR(file) {
				console.log(file);
			},
			// MR移出前
			beforeRemoveMR(file, fileList) {
				return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
			},
			// MR
			handleExceedMR(files, fileList) {
				this.$message.warning(
					`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileListMR.length} ${$t("filenum")}`);
			},
			// 上传Multi成功
			callbackMulti(res) {
				this.ruleForm.urlMulti = this.uploadMusicFileUrl + '/' + res.data.url;
				console.log(this.ruleForm.urlMulti, 'this.ruleForm.urlMulti')
			},
			// Multi校验格式
			testformatMulti(file) {
				const ar_isWAV = file.type === "audio/wav";
				const ar_isMP3 = file.type === "audio/mpeg";
				const ar_isLt100M = file.size / 1024 / 1024 < 100;
				// if( ar_isWAV || ar_isMP3){
				if (ar_isMP3) {
					if (!ar_isLt100M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightmulti"),
					});
					return false;
				}
			},
			// 移出Multi
			removeMulti() {
				this.$refs.Multi.clearFiles();
			},
			// Multi预览
			handlePreviewMulti(file) {
				console.log(file);
			},
			// Multi移出前
			beforeRemoveMulti(file, fileList) {
				return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
			},
			// Multi
			handleExceedMulti(files, fileList) {
				this.$message.warning(
					`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileListMulti.length} ${$t("filenum")}`);
			},
			changeKeyCascader(val) {
				if (val.length > 1) {
					this.keyValue = val.pop();
				}
			},
			// 上传封面成功
			callbackcover(res) {
				console.log(res, 'rrrrrrrrrr')
				this.ruleForm.coverImg = process.env.VUE_APP_FILE_PATH + '/' + res.data.url;
				console.log(this.imageUrl)
			},
			// 移除封面
			handleRemoveCover(file) {
				this.$refs.cover.clearFiles();
			},
			// 校验封面格式
			testformatCover(file) {
				const cover_isJPG = file.type === "image/jpeg";
				const cover_isPNG = file.type === "image/png";
				const cover_isLt10M = file.size / 1024 / 1024 < 10;
				if (cover_isJPG || cover_isPNG) {
					if (!cover_isLt10M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightcover"),
					});
					return false;
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// handleRemove(file, fileList) {
			//   console.log(file, fileList);
			// },
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(
					`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileList.length} ${$t("filenum")}`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
			},
			// handleAvatarSuccess(res, file) {
			// 	// this.imageUrl = URL.createObjectURL(file.raw);
			// },
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error(this.$t("uploadfomat"));
				}
				if (!isLt2M) {
					this.$message.error(this.$t("uploadsize"));
				}
				return isJPG && isLt2M;
			},
			// showAgreement() {
			// 	console.log('协议')
			// },
			initTagList() {
				tagList({})
					.then((res) => {
						console.log('获取标签信息成功:', res.data)
						//风格
						let styleOption = {}
						for (let i = 0; i < res.data.list.genre_arr.length; i++) {
							if (res.data.list.genre_arr[i].child) {
								for (let j = 0; j < res.data.list.genre_arr[i].child.length; j++) {
									styleOption.value = res.data.list.genre_arr[i].child[j].id
									styleOption.label = res.data.list.genre_arr[i].child[j].name
									styleOption.disabled = false
									this.styleList.push(JSON.parse(JSON.stringify(styleOption)))
								}
							}
						}
						//歌手
						let singerOption = {}
						for (const key in res.data.list.suit_arr) {
							console.log(key)
							singerOption.value = key
							singerOption.label = res.data.list.suit_arr[key]
							this.singerList.push(JSON.parse(JSON.stringify(singerOption)))
						}
						//乐器
						let musicOption = {}
						for (const key in res.data.list.instrument_arr) {
							console.log(key)
							musicOption.value = res.data.list.instrument_arr[key].id
							musicOption.label = res.data.list.instrument_arr[key].name
							this.musicalList.push(JSON.parse(JSON.stringify(musicOption)))
						}
						//情绪
						let moodOption = {}
						for (const key in res.data.list.mood_arr) {
							console.log(key)
							moodOption.value = res.data.list.mood_arr[key].id
							moodOption.label = res.data.list.mood_arr[key].name
							this.moodList.push(JSON.parse(JSON.stringify(moodOption)))
						}
					})
					.catch((err) => {
						console.log(err, "所有标签列表失败");
					});
				suitList({}).then(res => {
					// console.log(res, 'ressssss')
					// let arr = []
					// let obj = res.data.list
					// for(var key in obj){
					//     arr.push({id:key,name:obj[key]})
					// }
					// console.log(arr,'arrrrrrrrrr')
					// // this.singerList = arr
					console.log('获取适合歌手成功:', res)

				}).catch(err => {
					console.log('获取适合歌手失败:', err)
				})
			},
			changeSingerCascader(val) {
				console.log(val, 'changeSingerCascader')
				// console.log(val)
				// this.singerValue = val
				if (val.length > 1) {
					this.singerValue = val.pop();
				}
			},
			changeStyleCascader(val) {
				console.log(val, 'changeStyleCascader')
				console.log(val)
				console.log(this.styleList)
				this.styleList = this.setStyleSelect(this.styleList);

			},
			setStyleSelect(items) {
				items.forEach((item, index) => {
					const disable = this.styleValue.length >= 4;
					items[index].disabled =
						this.styleValues.indexOf(item.value) > -1 ? false : disable;
				});
				return items;
			},
			changeMusicalCascader(val) {
				// console.log(val)
				// if (val.length > 1) {
				// 	this.musicalValue = val.pop();
				// }
			},
			changeMoodCascader(val) {
				console.log(val)
				if (val.length > 1) {
					this.moodValue = val.pop();
				}
			},
			// issue() {
			// 	console.log()
			// 	let params = {
			// 		title: this.ruleForm.title,
			// 		recommend: 2,
			// 		url: this.ruleForm.url,
			// 		images: this.imageurl,
			// 		suit: this.singerValue.join(","),
			// 		GenresId: this.styleValue.join(","),
			// 		InstrumentId: this.musicalValue.join(","),
			// 		moodId: this.moodValue.join(","),
			// 		prize: "",
			// 		bmp: "",
			// 		mr: "",
			// 		singer: this.ruleForm.singer,
			// 		lyrics: this.ruleForm.lyrics,
			// 		composition: this.ruleForm.composition,
			// 		arrangements: this.ruleForm.arrangements,
			// 	};
			// 	addMusic(params).then((res) => {
			// 		if (res.status == 1) {
			// 			this.$message({
			// 				type: "success",
			// 				message: res.msg,
			// 			});
			// 			this.$router.push("/personalCenter/myWork/workproducthmusic");
			// 		} else {
			// 			for (let item in res.data) {
			// 				setTimeout(() => {
			// 					this.$notify({
			// 						title: this.$t("sampleup_notify_title"),
			// 						type: "error",
			// 						message: res.data[item],
			// 					});
			// 				}, 1500);
			// 			}
			// 		}
			// 	});
			// },
			toregisterCreator() {
				if (this.xieyi === false) {
					this.$message({
						type: "error",
						message: this.$t("checkmusicianagreement"),
					});
				} else {
					let params = {
						name: this.ruleForm.name, //艺名
						headimage: this.ruleForm.headImgUrl,//头像
						// creator_type: this.ruleForm.type,//认证类型
						// composer_type: this.composer_type,
						// certificate_type: this.ruleForm.certificates,//证件类型
						// certificate: this.ruleForm.huzhaoUrl,//证件正面照
						// certificate_code: this.ruleForm.idcard,//证件号码
						real_name: this.ruleForm.realname,//真实姓名
						countryId: this.ruleForm.country,//国家idruleForm.country
						genresId: this.styleValue.join(","), //歌曲风格
						instrumentsId: this.musicalValue.join(""), //乐器
						personalIntroduction: this.ruleForm.desc,//个人介绍
						kakaotalkID: this.ruleForm.kakaotalkID,//kakaotalk ID仅韩语环境下必填，中文环境隐藏
						insAccount: this.ruleForm.insAccount,//INS账号 仅韩语情况下必填，中文环境隐藏
						// title: this.ruleForm.title, //歌曲名称
						// recommend: this.ruleForm.workType,
						// url: this.ruleForm.urlAR,
						// mr: this.ruleForm.urlMR,
						// images: this.ruleForm.coverImg,
						// suit: this.singerValue.join(","),
						// musicGenresId: this.styleValue.join(","),
						// musicInstrumentId: this.musicalValue.join(","),
						// moodId: this.moodValue.join(","),
						// prize: this.price,
						// bpm: this.ruleForm.bpm,
						// multi: this.ruleForm.urlMulti,
						// toneId: this.keyValue.join(","),
						// activity: this.activity,
						// mr:this.mr
					};
					registerCreator(params).then((res) => {
						if (res.status === 1) {
							this.$message({
								type: "success",
								message: res.msg,
							});

							localStorage.removeItem("token");
							
							this.$router.push("/");

							this.successdialog = true;
						} else {
							for (let item in res.data) {
								setTimeout(() => {
									this.$notify({
										title: this.$t("demoupnotifytitle"),
										type: "error",
										message: res.data[item],
									});
								}, 1500);
							}
						}
					});
				}
			},
		}
	}
</script>

<style scoped lang='less'>
	::v-deep .el-button--primary {
		border-color: #3370ff;
		/* color: @inputHoverColor; */
		background-color: #3370ff !important;
	
		&:hover {
			opacity: .8;
		}
	}
	.btn-wrap {
		display: flex;
		align-items: center;
	}

	.btn-wrap-text {
		padding-left: 10px;
	}

	/deep/.idup .el-upload-list__item-actions {
		/* width: 188px !important;
		height: 110px !important; */
		width: 80px !important;
		height: 80px !important;
	}

	/deep/.idup .el-upload-list__item-thumbnail {
		/* width: 188px !important;
		height: 110px !important; */
		width: 80px !important;
		height: 80px !important;
	}

	/deep/.idup li>div {
		/* width: 188px !important;
		height: 110px !important; */
		width: 80px !important;
		height: 80px !important;
	}

	/deep/.idup li {
		/* width: 188px !important;
		height: 110px !important; */
		width: 80px !important;
		height: 80px !important;
	}

	.huzhao {
		position: relative;
	}

	.huzhao>img {
		display: inline-block;
		width: 185px;
		height: 106px;
		position: relative;
		top: -21px;
	}

	.huzhao>p {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -60%);
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.huzhaofont:hover {
		color: rgba(120, 136, 221, 0.8);
	}

	::v-deep .el-divider {
		background-color: @uploadInputBackgroundColor;
	}

	/deep/.el-upload__input {
		display: none;
	}

	/deep/.el-upload {
		width: 80px;
		height: 80px;
		position: relative;
	}

	/deep/.el-icon-plus {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

	}

	/deep/.el-upload-list__item-thumbnail {
		display: inline-block;
		width: 80px;
		height: 80px;
	}

	/deep/.el-upload-list--picture-card>.el-upload-list__item-actions {
		width: 80px;
		height: 80px;
	}

	/deep/.is-ready {
		width: 80px;
		height: 80px;
	}

	/deep/.el-upload-list--text>.el-upload-list__item {
		width: 300px;
		height: 30px;
		position: relative;
		top: -10px;
	}
	/deep/.el-upload-list--picture-card .el-upload-list__item{
		width: 80px;
		height: 80px;
	}

	/deep/.el-upload-list--text {
		clear: both;
	}

	.upload-ar>div:nth-child(1) {
		float: left;
	}

	.upload-ar>div:nth-child(2) {
		float: right;
		margin-right: 300px;
	}

	/deep/.el-upload--text {
		height: 50px;
	}

	/deep/.coverup .is-success {
		width: 80px;
		height: 80px;
	}

	::v-deep .collaboration-area-form .avatar-uploader .el-upload {
		background: @recommendMusicianListBGColor;
	}

	::v-deep .el-upload .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		display: flex;
		align-items: center;
	}

	::v-deep .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		width: 80px;
		height: 80px;
	}

	.collaboration-area {
		background-color: @recommendMusicianListBGColor;
		padding: 60px;
		margin-top: 16px;
		border-radius: 6px;

		&-form {
			width: 70%;
			margin: 0 auto;

			// 上传样式
			::v-deep .avatar-uploader {
				display: flex;

				.el-upload {
					border: 1px dashed @inputHoverColor;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;

					&:hover {
						opacity: .8;
					}
				}

			}

			::v-deep .el-button--small {
				border: 1px solid @inputHoverColor;
				color: @inputHoverColor;
				background-color: @uploadInputBackgroundColor  !important;

				&:hover {
					opacity: .8;
				}
			}

			::v-deep .el-form-item__label {
				color: @uploadFormTextColor;
			}

			::v-deep .el-tag {
				background: @tagBgColor  !important;
				color: @uploadFormTextColor;
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: @inputHoverColor;
				width: 80px;
				height: 80px;
				line-height: 80px;
				text-align: center;
			}

			.avatar {
				width: 80px;
				height: 80px;
				display: block;
			}

			::v-deep .el-upload__tip {
				padding-top: 64px;
				margin-left: 38px;
				line-height: 16px;
			}

			// 修改cascader宽度
			::v-deep .el-cascader {
				width: 100%;

				//&:hover {
				//  border: 1px solid #fff;
				//}
			}

			// 修改input背景色
			::v-deep .el-input__inner {
				height: 42px;
				background-color: @uploadInputBackgroundColor;
				border: 1px solid @uploadInputBackgroundColor;
				color: @uploadFormTextColor;

				&:hover {
					border: 1px solid @inputHoverColor;
				}
			}

			::v-deep .el-form-item.is-error .el-input__inner,
			.el-form-item.is-error .el-input__inner:focus,
			.el-form-item.is-error .el-textarea__inner,
			.el-form-item.is-error .el-textarea__inner:focus,
			.el-message-box__input input.invalid,
			.el-message-box__input input.invalid:focus {
				border-color: #F56C6C;
			}

			::v-deep .el-checkbox__label {
				color: @uploadFormTextColor  !important;
			}

			::v-deep .el-tag {
				background-color: rgba(255, 255, 255, .08);
			}

			::v-deep .el-input-group__append,
			.el-input-group__prepend {
				background: rgba(0, 0, 0, 0);
				border: none;
			}

			::v-deep .el-textarea {
				width: 100% !important;
				background: @uploadInputBackgroundColor;
				color: @uploadFormTextColor;
			}

			::v-deep .el-textarea__inner {
				width: 100% !important;
				height: 128px;
				background: @uploadInputBackgroundColor;
				border: none;
				resize: none;
				/* color: @recommendMusicianListTextColor; */
				color: @uploadFormTextColor;
				font-size: 14px;
				/* font-weight: 600; */

				font-family: PingFangSC-Regular, PingFang SC;

				&:hover {
					border: 1px solid @popupCancelTextColor;
				}
			}

			::v-deep .el-input__count {
				background: rgba(0, 0, 0, 0);
			}

			.sex {
				::v-deep .el-radio-group {
					display: flex;
					// background: @recommendMusicianListBGColor;
				}

				::v-deep .el-radio {
					/* width: 106px; */
					padding: 0 10px;
					box-sizing: border-box;
					height: 42px;
					background: @personInfoFormColor;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;

					&:hover {
						/* border: 1px solid @popupCancelTextColor;
					  box-sizing: border-box; */
					}
				}

				::v-deep .el-radio__inner {
					color: #999999;

					// background-color: rgba(34, 36, 38, 0.0500);
				}

				::v-deep .el-radio__input.is-checked .el-radio__inner {
					border-color: @personInfoFormColor;
					background-color: @recommendMusicianListTextColor;
				}

				::v-deep .el-radio__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-radio__inner::after {
					width: 0.25rem;
					height: 0.25rem;
					border-radius: 100%;
					background-color: @personInfoRadioBgColor;
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
				}

				// ::v-deep .el-radio__input .is-checked{
				// 	background-color: #222426;
				// }
			}

			.submit {
				display: flex;
				align-items: center;
				/*垂直方向居中*/
				justify-content: center;
				/*水平方向居中*/
			}
		}

	}
</style>

<style>

</style>
